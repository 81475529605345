<template>
  <div class="item_category_items">
    <div class="item_category_items__table-container" ref="tableContainer" @scroll="handleScroll">
      <table class="item_category_items__table">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Unidade</th>
            <th>Preço de Custo</th>
            <th>Preço de Venda</th>
            <th>Markup</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="loading && items.length === 0">
            <tr>
              <td colspan="5" class="item_category_items__loading">
                <div class="item_category_items__message">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    size="24"
                    width="2"
                  ></v-progress-circular>
                  <span>Buscando itens...</span>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr 
              v-for="item in items" 
              :key="item.id"
              class="item_category_items__row"
              @click="handleItemClick(item)"
            >
              <td class="item_category_items__cell item_category_items__cell--name" :title="item.name">{{ item.name }}</td>
              <td class="item_category_items__cell">{{ getUnitName(item.unit) }}</td>
              <td class="item_category_items__cell item_category_items__cell--amount">{{ formatCurrency(item.amount_cost) }}</td>
              <td class="item_category_items__cell item_category_items__cell--amount">{{ formatCurrency(item.amount_sale) }}</td>
              <td class="item_category_items__cell item_category_items__cell--markup">{{ item.margin }}%</td>
            </tr>
            <tr v-if="loading">
              <td colspan="5" class="item_category_items__loading">
                <div class="item_category_items__message">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    size="24"
                    width="2"
                  ></v-progress-circular>
                  <span>Carregando mais itens...</span>
                </div>
              </td>
            </tr>
            <tr v-if="!loading && !hasMore && items.length > 0">
              <td colspan="5" class="item_category_items__end">
                <div class="item_category_items__message">
                  <v-icon color="success">check_circle</v-icon>
                  <span>Você chegou ao final da lista</span>
                </div>
              </td>
            </tr>
            <tr v-if="!loading && hasMore && items.length > 0">
              <td colspan="5" class="item_category_items__load-more">
                <div class="item_category_items__message">
                  <v-btn
                    color="primary"
                    text
                    @click="$emit('load-more')"
                    class="item_category_items__load-more-btn"
                  >
                    <v-icon left>expand_more</v-icon>
                    Carregar mais itens
                  </v-btn>
                </div>
              </td>
            </tr>
            <tr v-if="!loading && items.length === 0">
              <td colspan="5" class="item_category_items__empty">
                <div class="item_category_items__message">
                  <v-icon color="grey">inventory_2</v-icon>
                  <span>Nenhum item encontrado nesta categoria</span>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemsList',
  props: {
    items: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    hasMore: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getUnitName(unit) {
      return unit && unit.code ? unit.code : '-'
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(value || 0)
    },
    handleScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target
      const threshold = 100

      if (scrollHeight - scrollTop - clientHeight < threshold && !this.loading) {
        this.$emit('load-more')
      }
    },
    handleItemClick(item) {
      this.$emit('item-click', item)
    }
  }
}
</script>

<style scoped>
.item_category_items {
  height: 100%;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
}

.item_category_items__table-container {
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #f1f1f1;
  position: relative;
}

.item_category_items__table-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.item_category_items__table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.item_category_items__table-container::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.item_category_items__table-container::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.item_category_items__table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 14px;
  table-layout: fixed;
}

.item_category_items__table th,
.item_category_items__table td {
  padding: 2px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item_category_items__table th:nth-child(1),
.item_category_items__table td:nth-child(1) {
  width: 40%;
}

.item_category_items__table th:nth-child(2),
.item_category_items__table td:nth-child(2) {
  width: 10%;
}

.item_category_items__table th:nth-child(3),
.item_category_items__table td:nth-child(3),
.item_category_items__table th:nth-child(4),
.item_category_items__table td:nth-child(4) {
  width: 15%;
  text-align: right;
}

.item_category_items__table th:nth-child(5),
.item_category_items__table td:nth-child(5) {
  width: 10%;
  text-align: right;
}

.item_category_items__table th {
  background-color: #f8f9fa;
  color: #495057;
  font-weight: 600;
  text-align: left;
  padding: 16px;
  border-bottom: 2px solid #e9ecef;
  position: sticky;
  top: 0;
  z-index: 1;
  white-space: nowrap;
}

.item_category_items__row {
  transition: all 0.2s ease-in-out;
}

.item_category_items__row:nth-child(even) {
  background-color: #f8f9fa;
}

.item_category_items__row:hover {
  background-color: #e9ecef !important;
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.item_category_items__cell {
  padding: 16px;
  border-bottom: 1px solid #e9ecef;
  color: #495057;
  transition: background-color 0.2s ease;
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item_category_items__cell--name {
  font-weight: 500;
  color: #212529;
  position: relative;
  cursor: pointer;
}

.item_category_items__cell--name:hover::after {
  content: attr(title);
  position: absolute;
  left: 0;
  top: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 13px;
  z-index: 1000;
  white-space: normal;
  max-width: 300px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.item_category_items__cell--amount {
  font-family: monospace;
  text-align: right;
}

.item_category_items__cell--markup {
  font-weight: 500;
  text-align: right;
  color: #28a745;
}

.item_category_items__loading,
.item_category_items__end,
.item_category_items__empty {
  text-align: center;
  padding: 32px;
  color: #6c757d;
  background-color: #f8f9fa;
  width: 100%;
  table-layout: fixed;
}

.item_category_items__message {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  min-height: 48px;
}

.item_category_items__end {
  color: #28a745;
  background-color: #f8fff9;
}

.item_category_items__empty {
  color: #6c757d;
  background-color: #f8f9fa;
}

.item_category_items__empty .v-icon {
  font-size: 24px;
  color: #adb5bd;
}

.item_category_items__loading .v-progress-circular {
  margin-right: 8px;
}

.item_category_items__message span {
  font-size: 14px;
  font-weight: 500;
}

.item_category_items__load-more {
  text-align: center;
  padding: 16px;
  background-color: #f8f9fa;
  width: 100%;
  table-layout: fixed;
}

.item_category_items__load-more-btn {
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
}

.item_category_items__load-more-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(25, 118, 210, 0.2);
}

.item_category_items__load-more .v-icon {
  transition: transform 0.3s ease;
}

.item_category_items__load-more-btn:hover .v-icon {
  transform: translateY(2px);
}

/* Animações de transição */
.item-list-enter-active,
.item-list-leave-active {
  transition: all 0.3s ease;
}

.item-list-enter,
.item-list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.item-list-move {
  transition: transform 0.3s ease;
}

@media (max-width: 768px) {
  .item_category_items__table th,
  .item_category_items__table td {
    padding: 12px;
    font-size: 13px;
  }

  .item_category_items__loading,
  .item_category_items__end,
  .item_category_items__empty {
    padding: 24px;
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .item_category_items__table th,
  .item_category_items__table td {
    padding: 10px;
    font-size: 12px;
  }

  .item_category_items__loading,
  .item_category_items__end,
  .item_category_items__empty {
    padding: 20px;
    font-size: 12px;
  }
}
</style> 