
import BancoPan from './BancoPan/BancoPan'
import BancoBmg from './BancoPan/BancoPan'
import BancoMaster from './Master/BancoMaster'
import BancoDaycoval from './Master/BancoMaster'
import BancoFacta from './Master/BancoMaster'

// caixa
// máster
// facta
// c6
// kardbank
// meucashcard
// BIB
// ole
// itau
// Santander
// daycoval
// bmg
// pan
// safra
// quero+ (a gente ainda nao cadastrou esse no sistema)
// Fox ( tb nao tem cadastro ainda)
// Digio (tb nao tem cadastro ainda)
// Happy ( tb nao tem cadastro ainda)
// Banco do Brasil ( tb n tem cadastro )
// VCTEX (tb n tem cadastro)

export default [
  {
    id: 1,
    name: 'Banco Master',
    code: 'banco-master',
    logo: '/static/integration/logos/banco-master.png',
    fields: BancoMaster,
  },
  {
    id: 2,
    name: 'Banco Pan',
    code: 'banco-pan',
    logo: '/static/integration/logos/banco-pan.webp',
    fields: BancoPan,
  },
  {
    id: 3,
    name: 'Banco Daycoval',
    code: 'banco-daycoval',
    logo: '/static/integration/logos/banco-daycoval.png',
    fields: BancoDaycoval,
  },
  {
    id: 4,
    name: 'Banco Facta',
    code: 'banco-facta',
    logo: '/static/integration/logos/banco-facta.png',
    fields: BancoFacta,
  },
  {
    id: 5,
    name: 'Banco BMG',
    code: 'banco-bmg',
    logo: '/static/integration/logos/banco-bmg.png',
    fields: BancoBmg,
  },
  {
    id: 7,
    name: 'Banco C6',
    code: 'banco-c6-bank',
    logo: '/static/integration/logos/banco-c6-bank.png',
    fields: BancoMaster,
  },
  {
    id: 8,
    name: 'Banco Kardbank',
    code: 'banco-kardbank',
    logo: '/static/integration/logos/banco-kardbank.png',
    fields: BancoMaster,
  },
  {
    id: 9,
    name: 'Banco Meucashcard',
    code: 'banco-meucashcard',
    logo: '/static/integration/logos/banco-meucashcard.png',
    fields: BancoMaster,
  },
  {
    id: 10,
    name: 'Banco BIB',
    code: 'banco-bib',
    logo: '/static/integration/logos/banco-bib.png',
    fields: BancoMaster,
  },
  {
    id: 11,
    name: 'Banco Ole',
    code: 'banco-ole',
    logo: '/static/integration/logos/banco-ole.png',
    fields: BancoMaster,
  },
  {
    id: 12,
    name: 'Banco Itau',
    code: 'banco-itau',
    logo: '/static/integration/logos/banco-itau.jpg',
    fields: BancoMaster,
  },
  {
    id: 14,
    name: 'Banco Santander',
    code: 'banco-santander',
    logo: '/static/integration/logos/banco-santander.png',
    fields: BancoMaster,
  },
  {
    id: 15,
    name: 'Banco do Brasil',
    code: 'banco-do-brasil',
    logo: '/static/integration/logos/banco-do-brasil.png',
    fields: BancoMaster,
  },
  {
    id: 17,
    name: 'Banco VCTEX',
    code: 'banco-vctex',
    logo: '/static/integration/logos/banco-cvctex.webp',
    fields: BancoMaster,
  },
  {
    id: 18,
    name: 'Banco Fox',
    code: 'banco-fox',
    logo: '/static/integration/logos/banco-generico.webp',
    fields: BancoMaster,
  },
  {
    id: 19,
    name: 'Banco Digio',
    code: 'banco-digio',
    logo: '/static/integration/logos/banco-digio.png',
    fields: BancoMaster,
  },
  {
    id: 20,
    name: 'Banco Happy',
    code: 'banco-happy',
    logo: '/static/integration/logos/banco-happy.png',
    fields: BancoMaster,
  },
  {
    id: 21,
    name: 'Caixa Econômica Federal',
    code: 'banco-caixa',
    logo: '/static/integration/logos/banco-caixa.jpg',
    fields: BancoMaster,
  },
  {
    id: 22,
    name: 'Banco Safra',
    code: 'banco-safra',
    logo: '/static/integration/logos/banco-safra.png',
    fields: BancoMaster,
  },
  {
    id: 23,
    name: 'Banco Quero+',
    code: 'banco-quero-plus',
    logo: '/static/integration/logos/banco-quero+.png',
    fields: BancoMaster,
  },
  {
    id: 24,
    name: 'Banco Amigoz Consignado',
    code: 'banco-amigoz-consignado',
    logo: '/static/integration/logos/amigozconsignado.jpg',
    fields: BancoMaster,
  },
  {
    id: 25,
    name: 'Banco Futuro Revidência',
    code: 'banco-futuro-revidencia',
    logo: '/static/integration/logos/futuro-revidencia.png',
    fields: BancoPan,
  },

  
]
