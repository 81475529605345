<template>
  <div class="selected_category">
    <div class="selected_category__header">
      <div class="selected_category__title">
        <div class="selected_category__title-content">
          <v-icon>{{ getCategoryIcon(category.type) }}</v-icon>
          <h2 v-if="!isSearching">{{ category.name }}</h2>
          <div v-else class="selected_category__search">
            <div class="selected_category__search-wrapper">
              <input
                v-model="searchQuery"
                type="text"
                placeholder="Buscar por nome..."
                @input="handleSearchInput"
                @keyup.enter="handleSearchEnter"
                ref="searchInput"
                class="selected_category__search-input"
              />
              <button
                v-if="searchQuery"
                @click="clearSearch"
                class="selected_category__search-clear"
                type="button"
              >
                <v-icon small>close</v-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="selected_category__actions" v-if="!isSearching">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="editCategory"
              >
                <v-icon>edit</v-icon>
              </v-btn>
            </template>
            <span>Editar Categoria</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="setMarkup"
              >
                <v-icon>trending_up</v-icon>
              </v-btn>
            </template>
            <span>Definir Markup</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                :loading="loading"
                v-bind="attrs"
                v-on="on"
                @click="refreshItems"
              >
                <v-icon>refresh</v-icon>
              </v-btn>
            </template>
            <span>Atualizar Lista</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="toggleSearch"
              >
                <v-icon>search</v-icon>
              </v-btn>
            </template>
            <span>Buscar</span>
          </v-tooltip>
          <v-menu
            offset-y
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                  <v-btn
                    icon
                    v-bind="{ ...attrs, ...tooltipAttrs }"
                    v-on="{ ...on, ...tooltipOn }"
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
                <span>Adicionar {{ getItemTypeLabel(category.type) }}</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-tile @click="addExistingItem">
                <v-list-tile-title>
                  <v-icon left>link</v-icon>
                  Adicionar {{ getItemTypeLabel(category.type) }}s Existentes
                </v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click="createNewItem">
                <v-list-tile-title>
                  <v-icon left>add_circle</v-icon>
                  Criar Novo {{ getItemTypeLabel(category.type) }}
                </v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </div>
        <div class="selected_category__actions" v-else>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="toggleSearch"
              >
                <v-icon>arrow_back</v-icon>
              </v-btn>
            </template>
            <span>Voltar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="toggleSearch"
              >
                <v-icon>search</v-icon>
              </v-btn>
            </template>
            <span>Buscar</span>
          </v-tooltip>
        </div>
      </div>
      <div class="selected_category__stats">
        <div class="selected_category__stat markup">
          <div class="selected_category__stat-icon">
            <v-icon>trending_up</v-icon>
          </div>
          <div class="selected_category__stat-content">
            <span class="selected_category__stat-label">Markup</span>
            <span class="selected_category__stat-value">{{ category.profit_margin }}%</span>
          </div>
        </div>
        <div class="selected_category__stat items">
          <div class="selected_category__stat-icon">
            <v-icon>inventory</v-icon>
          </div>
          <div class="selected_category__stat-content">
            <span class="selected_category__stat-label">{{ getItemTypeLabel(category.type) }}s</span>
            <span class="selected_category__stat-value">{{ category.items_count }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="selected_category__content">
      <items-list 
        :items="items"
        :loading="loading"
        :has-more="hasMore"
        @load-more="loadMore"
        @item-click="handleItemClick"
        ref="itemsList"
      />
    </div>
    <markup-dialog
      v-model="showMarkupDialog"
      :markup="category.profit_margin"
      :items-count="total"
      :item-type="itemType"
      :loading="loading"
      @confirm="handleMarkupConfirm"
    />
  </div>
</template>

<script>
import ItemsList from './ItemsList.vue'
import MarkupDialog from './MarkupDialog.vue'
import { callApi } from '@/default/service/Api'

export default {
  name: 'SelectedCategory',
  components: {
    ItemsList,
    MarkupDialog
  },
  props: {
    category: {
      type: Object,
      required: true
    },
    itemType: {
      type: String,
      default: 'item'
    }
  },
  data() {
    return {
      items: [],
      currentPage: 1,
      total: 0,
      loading: false,
      hasMore: true,
      isSearching: false,
      searchQuery: '',
      searchTimeout: null,
      showMarkupDialog: false
    }
  },
  methods: {
    getCategoryIcon(type) {
      switch (type) {
        case 1:
        case 'product':
          return 'inventory'
        case 2:
        case 'service':
          return 'miscellaneous_services'
        default:
          return 'category'
      }
    },
    getItemTypeLabel(type) {
      switch (type) {
        case 1:
        case 'product':
          return 'produto'
        case 2:
        case 'service':
          return 'serviço'
        default:
          return 'item'
      }
    },
    getItems(page = 1) {
      if (this.loading || (!this.hasMore && page > 1)) return

      this.loading = true

      const endpoint = this.itemType === 'item' 
        ? 'register/item'
        : `register/${this.itemType}`

      callApi.get({
        uri: endpoint,
        params: {
          page,
          limit: 20,
          item_category_id: this.category.id,
          order: 'name,asc',
          search: this.searchQuery || undefined
        },
        sucess: (response) => {
          const { data, total, current_page, last_page } = response?.data || {}
          
          if (page === 1) {
            this.items = data || []
          } else {
            this.items = [...this.items, ...(data || [])]
          }

          this.total = total || 0
          this.currentPage = current_page || 1
          this.hasMore = current_page < last_page
          this.loading = false
        },
        error: (error) => {
          console.error('Erro ao carregar itens:', error)
          this.loading = false
        }
      })
    },
    loadMore() {
      if (!this.loading && this.hasMore) {
        this.getItems(this.currentPage + 1)
      }
    },
    editCategory() {
      this.$WiEditDialog({
        wiConfig: `${this.itemType}-category`,
        data: this.category,
        onSubmit: () => {
          this.$emit('refresh')
        }
      })
    },
    setMarkup() {
      this.showMarkupDialog = true
    },
    handleMarkupConfirm() {
      this.$WiApiPost({
        uri: `register/${this.itemType}/category/set-markup`,
        data: {
          category_id: this.category.id,
          markup: this.category.profit_margin
        },
        config: {
          msgSucess: {
            title: 'Markup definido com sucesso.',
            text: 'O resultado da solicitação irá aparecer na timeline.'
          },
          msgError: {
            title: 'Erro ao processar solicitação',
            text: 'Tente novamente ou contacte o suporte.'
          },
          msgLoad: {
            title: 'Definindo markup...',
            text: 'Aguarde alguns instantes...'
          },
          msgAsk: {
            title: `Você está prestes a alterar o preço de venda de ${this.total} ${this.getItemTypeLabel(this.itemType)}s da categoria ${this.category.name} em ${this.category.profit_margin}%`,
            text: 'Esta ação só poderá ser desfeita manualmente.'
          }
        },
        callback: () => {
          this.getItems(1)
          this.showMarkupDialog = false
        }
      })
    },
    addExistingItem() {
      this.$WiListDialog({
        wiConfig: `${this.itemType}`,
        callback: (data) => {
          this.addItems(data)
        },
        mode: 'select',
      })
    },
    createNewItem() {
      this.$WiEditDialog({
        wiConfig: `${this.itemType}`,
        data: {
          item_category_id: this.category.id
        },
        onSubmit: () => {
          this.getItems(1)
        }
      })
    },
    addItems(items) {
      let itemsArray = []
      if (Array.isArray(items)) {
        itemsArray = items.map(item => item.id)
      } else {
        itemsArray.push(items.id)
      }
      this.$WiApiPost({
        uri: `register/${this.itemType}/category/add-items`,
        data: {
          category_id: this.category.id,
          items: itemsArray
        },
        config: {
          msgLoad: {
            title: `Adicionando itens...`,
            text: 'Não irá demorar mais que alguns segundos.'
          },
          msgSucess: {
            title: 'Itens adicionados com sucesso.',
            text: 'O resultado da solicitação irá aparecer na timeline.'
          },
          msgError: {
            title: 'Erro ao processar solicitação',
            text: 'Tente novamente ou contacte o suporte.'
          },
          msgAsk: {
            title: `Tem certeza que deseja adicionar ${itemsArray.length} ${this.getItemTypeLabel(this.itemType)}${itemsArray.length > 1 ? 's' : ''}?`,
            text: 'Esta ação só poderá ser desfeita manualmente.'
          }
        },
        callback: () => {
          this.getItems(1)
        }
      })
    },
    refreshItems() {
      this.items = []
      this.currentPage = 1
      this.hasMore = true
      this.getItems(1)
    },
    handleItemClick(item) {
      this.$WiEditDialog({
        wiConfig: this.itemType,
        data: item,
        onSubmit: () => {
          this.getItems(1)
        }
      })
    },
    toggleSearch() {
      this.isSearching = !this.isSearching
      if (this.isSearching) {
        this.$nextTick(() => {
          this.$refs.searchInput.focus()
        })
      } else {
        this.clearSearch()
      }
    },
    handleSearchInput() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      
      this.searchTimeout = setTimeout(() => {
        if (this.searchQuery.length >= 3 || this.searchQuery.length === 0) {
          this.refreshItems()
        }
      }, 300)
    },
    handleSearchEnter() {
      if (this.searchQuery.length >= 3) {
        this.refreshItems()
      }
    },
    clearSearch() {
      this.searchQuery = ''
      this.refreshItems()
    }
  },
  watch: {
    'category.id': {
      handler() {
        this.items = []
        this.currentPage = 1
        this.hasMore = true
        this.getItems()
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.selected_category {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.selected_category__header {
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  gap: 16px;
}

.selected_category__title {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
}

.selected_category__title-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.selected_category__title-content h2 {
  margin: 0;
  font-size: 16px;
  color: #333;
  font-weight: 500;
}

.selected_category__title-content .v-icon {
  font-size: 20px;
  color: #1976d2;
}

.selected_category__actions {
  display: flex;
  gap: 4px;
  align-items: center;
}

.selected_category__stats {
  display: flex;
  gap: 8px;
}

.selected_category__stat {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f8f9fa;
  border-radius: 6px;
  padding: 6px 12px;
  min-width: 100px;
}

.selected_category__stat-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: #e3f2fd;
  border-radius: 6px;
  color: #1976d2;
}

.selected_category__stat-content {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.selected_category__stat-label {
  font-size: 12px;
  color: #666;
  line-height: 1.2;
}

.selected_category__stat-value {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  line-height: 1.3;
}

.selected_category__stat.markup .selected_category__stat-icon {
  background-color: #e8f5e9;
  color: #4caf50;
}

.selected_category__stat.items .selected_category__stat-icon {
  background-color: #e3f2fd;
  color: #1976d2;
}

.selected_category__content {
  flex: 1;
  overflow: hidden;
}

.selected_category__search {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  max-width: 300px;
}

.selected_category__search-wrapper {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
}

.selected_category__search-input {
  width: 100%;
  height: 32px;
  padding: 0 12px;
  padding-right: 32px;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  font-size: 14px;
  color: #333;
  background-color: #f8f9fa;
  transition: all 0.2s ease;
  outline: none;
}

.selected_category__search-input::placeholder {
  color: #adb5bd;
}

.selected_category__search-input:focus {
  border-color: #1976d2;
  background-color: #fff;
  box-shadow: 0 0 0 3px rgba(25, 118, 210, 0.1);
}

.selected_category__search-clear {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #adb5bd;
  transition: color 0.2s ease;
}

.selected_category__search-clear:hover {
  color: #495057;
}

.selected_category__search-clear .v-icon {
  font-size: 16px;
}

@media (max-width: 768px) {
  .selected_category__header {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .selected_category__title {
    width: 100%;
    justify-content: space-between;
  }

  .selected_category__actions {
    width: 100%;
    justify-content: flex-start;
  }

  .selected_category__stats {
    width: 100%;
    justify-content: flex-start;
    gap: 12px;
  }

  .selected_category__stat {
    flex: 1;
    min-width: 120px;
  }

  .selected_category__search {
    max-width: 100%;
  }
}
</style> 