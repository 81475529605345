import { Model } from '@/default/model/Model'

export class ItemCategory extends Model {
  constructor (type = 'product') {
    super()
    this.type = type
    this.list = true
    this.id = {
      id: '549b7548',
      create: 'ba7aae1c',
      update: '1bced885',
      delete: 'd99b53a2'
    }
    this.title = {
      list: `Categorias de ${this.type === 'product' ? 'Produtos' : 'Serviços'}`,
      edit: {
        insert: `Nova categoria de ${this.type === 'product' ? 'produto' : 'serviço'}`,
        update: `Editando categoria de ${this.type === 'product' ? 'produto' : 'serviço'}`
      }
    }
    this.icon = 'category'
    this.apiUrl = `register/${this.type}/category`
    this.search = {
      placeholder: `Pesquise ID ou nome da categoria de ${this.type === 'product' ? 'produto' : 'serviço'}`,
      fields: ['id', 'name']
    }
    this.maxWidth = '400px'
    this.setFields([
      this.fieldSubHeader({
        text: `Informe o nome da categoria de ${this.type === 'product' ? 'produto' : 'serviço'}.`
      }),
      this.fieldText({
        value: 'name',
        text: 'Nome',
      }),
      this.fieldSubHeader({
        text: 'Informe o markup e a ordem da categoria.'
      }),
      this.fieldNumber({
        value: 'profit_margin',
        text: 'Markup',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        min: 0,
        step: 0.01
      }),
      this.fieldNumber({
        value: 'order',
        text: 'Ordem',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        min: 0
      }),
      this.fieldSubHeader({
        text: 'Informe a descrição da categoria.'
      }),
      this.fieldTextArea({
        value: 'description',
        text: 'Descrição',
        placeholder: 'Descreva a categoria...'
      })
    ])
    this.setFields([
      this.fieldList({
        value: 'id',
        text: 'ID'
      }),
      this.fieldList({
        value: 'name',
        text: 'Nome'
      }),
      this.fieldList({
        value: 'profit_margin',
        text: 'Markup'
      }),
      this.fieldList({
        value: 'order',
        text: 'Ordem'
      }),
      this.fieldList({
        value: 'description',
        text: 'Descrição'
      })
    ])
  }
}
